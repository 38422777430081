
import moment from 'moment'
import { Component, Vue, Ref } from 'vue-property-decorator'
import ListView from '@/components/ListView.vue'
import CSVExportBtn from '@/components/CSVExport.vue'

@Component({
  components: {
    ListView,
    CSVExportBtn,
  },
})
export default class SubscriptionListView extends Vue {
  headers = [
    { text: 'ID', value: 'id', width: 80, fixed: 'left' },
    { text: 'Plan', value: 'planName' },
    { text: 'Next Plan', value: 'nextPlanName' },
    { text: 'User', value: 'userEmail' },
    { text: 'Customer Code', value: 'customerCode' },

    { text: 'Charge Type', value: 'chargeType' },
    { text: 'Charge External ID', value: 'chargeExid' },
    { text: 'Start', value: 'startAt' },
    { text: 'Expires', value: 'expiringAt' },
    { text: 'Updated', value: 'updated' },
    { text: 'Created', value: 'created' },
  ]
  filterDefinition = {
    search: { type: String, default: null },
  }
  filter: null | Record<string, any> = null
  @Ref() readonly listView!: ListView

  get totalCount() {
    return this.$api.subscriptions().count
  }

  get csvHeaders() {
    return CSVHeaders
  }
  get csvFetch() {
    return () => this.$api.subscriptions().list(this.listView.query)
  }

  get staffLoggedIn(): boolean {
    return this.$store.getters.staffLoggedIn
  }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
  }
}

const extractTime = (value: string | undefined) =>
  value ? moment(value).format('HH:mm') : ''
const extractDate = (value: string | undefined) =>
  value ? moment(value).format('YYYY-MM-DD') : ''
const CSVHeaders = [
  { key: 'id', label: 'ID' },
  { key: 'planName', label: 'Plan' },
  { key: 'nextPlanName', label: 'Next Plan' },
  { key: 'userEmail', label: 'User' },
  { key: 'customerCode', label: 'Customer Code' },

  { key: 'chargeType', label: 'Charge Type' },
  { key: 'chargeExid', label: 'Charge External ID' },
  {
    key: (item: any) => extractDate(item.startAt),
    label: 'Start Date',
  },
  {
    key: (item: any) => extractTime(item.startAt),
    label: 'Start Time',
  },
  {
    key: (item: any) => extractDate(item.expiringAt),
    label: 'Expire Date',
  },
  {
    key: (item: any) => extractTime(item.expiringAt),
    label: 'Expire Time',
  },
  {
    key: (item: any) => extractDate(item.updated),
    label: 'Update Date',
  },
  {
    key: (item: any) => extractTime(item.updated),
    label: 'Update Time',
  },
  {
    key: (item: any) => extractDate(item.created),
    label: 'Create Date',
  },
  {
    key: (item: any) => extractTime(item.created),
    label: 'Create Time',
  },
]
